import React from 'react'
import { FormControlLabel, Checkbox, Box, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EMerchantProperties } from '@src/data/types/Constants'
import { Controller, useFormContext } from 'react-hook-form'
import { switchOnChangeAdapter } from '@src/services/mui-form-adapter'
import yup from '@src/data/types/common/yup-extended'
import { InferType } from 'yup'

const propertiesConfig = [
  {
    key: EMerchantProperties.noPaymentPolicy,
    titleKey: 'merchant.noPaymentPolicy.title',
    descriptionKey: 'merchant.noPaymentPolicy.description',
  },
  {
    key: EMerchantProperties.canSkipFlinks,
    titleKey: 'merchant.canSkipFlinks.title',
    descriptionKey: 'merchant.canSkipFlinks.description',
  },
  {
    key: EMerchantProperties.canPayBorrowers,
    titleKey: 'merchant.canPayBorrowers.title',
    descriptionKey: 'merchant.canPayBorrowers.description',
  },
  {
    key: EMerchantProperties.automaticIncomeApproval,
    titleKey: 'merchant.automaticIncomeApproval.title',
    descriptionKey: 'merchant.automaticIncomeApproval.description',
  },
] as const

const propertyConfigValidator = yup.boolean().required().default(false)

export const MerchantPropertiesSchema = yup.object().shape(
  propertiesConfig.reduce(
    (acc, curr) => {
      acc[curr.key] = propertyConfigValidator
      return acc
    },
    {} as { [K in EMerchantProperties]: typeof propertyConfigValidator },
  ),
)

const MerchantPropertiesPanel: React.FC = () => {
  const { t } = useTranslation()
  const { control } = useFormContext<InferType<typeof MerchantPropertiesSchema>>()

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {t('merchant.properties')}
      </Typography>

      <Stack gap={1}>
        {propertiesConfig.map((property) => (
          <Controller
            key={property.key}
            name={property.key}
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <FormControlLabel
                control={<Checkbox checked={value} {...field} onChange={switchOnChangeAdapter(onChange)} />}
                label={
                  <Box>
                    <Typography>{t(property.titleKey)}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t(property.descriptionKey)}
                    </Typography>
                  </Box>
                }
              />
            )}
          />
        ))}
      </Stack>
    </Box>
  )
}

export default MerchantPropertiesPanel
