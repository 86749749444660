import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import yup from '@src/data/types/common/yup-extended'
import { EMerchantReportFrequency } from '@src/data/types/Constants'
import { autoCompleteOnChangeAdapter } from '@src/services/mui-form-adapter'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InferType } from 'yup'

export const MerchantReportFormSchema = yup.object().shape({
  reportGenerationFrequency: yup.string().required().default(null),
  reportRecipientsEmailList: yup.array().of(yup.string().email().required()).default([]),
})

const MerchantReportConfigPanel = () => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext<InferType<typeof MerchantReportFormSchema>>()

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {t('merchant.reports.configureReport')}
      </Typography>
      <Stack gap={1}>
        <Controller
          name="reportGenerationFrequency"
          control={control}
          render={({ field: { value, onChange, ...fieldProps } }) => {
            return (
              <FormControl>
                <InputLabel id="merchant.reportGenerationFrequency">{t('merchant.reports.frequency')}</InputLabel>
                <Select
                  {...fieldProps}
                  labelId="merchant.reportGenerationFrequency"
                  value={value ?? 'none'}
                  onChange={onChange}
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="none">{t('common.eMerchantReportFrequency.never')}</MenuItem>
                  <MenuItem value={EMerchantReportFrequency.daily}>
                    {t('common.eMerchantReportFrequency.daily')}
                  </MenuItem>
                  <MenuItem value={EMerchantReportFrequency.weekly}>
                    {t('common.eMerchantReportFrequency.weekly')}
                  </MenuItem>
                  <MenuItem value={EMerchantReportFrequency.monthly}>
                    {t('common.eMerchantReportFrequency.monthly')}
                  </MenuItem>
                </Select>
              </FormControl>
            )
          }}
        />
        <Controller
          name="reportRecipientsEmailList"
          control={control}
          render={({ field: { value, onChange, ...fieldProps } }) => {
            return (
              <Autocomplete
                {...fieldProps}
                onChange={autoCompleteOnChangeAdapter(onChange)}
                multiple
                freeSolo
                value={value}
                options={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('merchant.reports.recipients')}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={!!errors.reportRecipientsEmailList}
                    helperText={errors.reportRecipientsEmailList ? t('common.errors.notValidEmail') : ''}
                  />
                )}
                renderTags={(tagValue: string[], getTagsProps) =>
                  tagValue.map((option, index) => <Chip {...getTagsProps({ index })} key={option} label={option} />)
                }
              />
            )
          }}
        />
      </Stack>
    </Box>
  )
}
export default MerchantReportConfigPanel
