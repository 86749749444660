// doit être fait en deux lignes sinon webpack optimise et n'inclut pas la string
// de plus, le toString est requis car en local c'est un string alors qu'en dev c'est une string
const useDevToolsStr = import.meta.env.VITE_USE_DEV_TOOLS ?? 'false'
export const useDevTools = useDevToolsStr.toString() === 'true'

export enum ELanguageAbr {
  French = 'fr',
  English = 'en',
}

export const ELanguageAbrList = Object.values(ELanguageAbr)

export enum ELanguage {
  French = 0,
  English = 1,
}

export enum EMerchantProperties {
  noPaymentPolicy = 'noPaymentPolicy',
  canSkipFlinks = 'canSkipFlinks',
  canPayBorrowers = 'canPayBorrowers',
  automaticIncomeApproval = 'automaticIncomeApproval',
}

export enum EMerchantReportFrequency {
  never = '',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export enum EProvince {
  quebec = 'QC',
  ontario = 'ON',
  novaScotia = 'NS',
  newBrunswick = 'NB',
  princeEdwardIsland = 'PE',
  newfoundland = 'NL',
  britishColombia = 'BC',
  saskatchewan = 'SK',
  alberta = 'AB',
  manitoba = 'MB',
  yukon = 'YT',
  nunavut = 'NU',
  northwestTerritories = 'NT',
}

export const EProvinceList = Object.values(EProvince)

export enum EServiceCategory {
  veterinary = 'veterinary',
  medical = 'medical',
  aesthetics = 'aesthetics',
  dentistry = 'dentistry',
  goodsAndServices = 'goodsAndServices',
}

export enum EFinancingCompany {
  IFinance = 'IFinance',
  Iceberg = 'Iceberg',
}

export const EServiceCategoryList = Object.values(EServiceCategory)

export enum ESourceSystem {
  alis = 'alis',
  monolith = 'monolith',
}

export enum EMerchantType {
  merchant = 0,
  banner = 1,
}

export enum EBannerType {
  user = 0,
  merchant = 1,
}

export enum EValueList {
  Tags = 'Tags',
}

export enum EUserRoles {
  systemAdmin = 'SystemAdmin',
  loanOfficer = 'LoanOfficer',
  juniorCreditAgent = 'JuniorCreditAgent',
  creditAgent = 'CreditAgent',
  supervisorCreditAgent = 'SupervisorCreditAgent',
  representative = 'Representative',
  reader = 'Reader',
  merchant = 'Merchant',
}

export enum EUserRights {
  canDeleteUser = 'canDeleteUser',
}

export enum EFinancingProgram {
  Auto = 'auto',
  IfXpress = 'ifxpress',
  Vlvr = 'vlvr',
  Products = 'products',
  Personal = 'personal',
}

export const DATE_MAX = new Date('9999-12-31')
